import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Contact from "../components/contact"
import Footer from "../components/footer"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const arrayOfImages = []

  frontmatter.images.galleryImages.map(galleryImage =>
    arrayOfImages.push(galleryImage)
  )

  const options = {
    buttons: {
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: true,
    },
  }

  return (
    <>
      <Seo
        title={frontmatter.title}
        image={frontmatter.mainImageUrl.childImageSharp.fluid.src}
      />
      <SimpleReactLightbox>
        <div>
          <Navbar />
          <div className="main">
            <Img
              fluid={frontmatter.mainImageUrl.childImageSharp.fluid}
              className="product__header_img"
            />
            <div className="bd-container">
              <h1 className="product__title">{frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: html }} />

              <div>
                <h2 className="product__title">Project Gallery</h2>
                <SRLWrapper options={options}>
                  <div className="product__gallery bd-grid">
                    <div className="product__gallery_content">
                      {arrayOfImages.map((image, i) => (
                        <GatsbyImage
                          key={i}
                          image={getImage(
                            image.childImageSharp.gatsbyImageData
                          )}
                          alt={"Project Gallery"}
                          className="product__gallery_image"
                        />
                      ))}
                    </div>
                  </div>
                </SRLWrapper>
              </div>
            </div>
          </div>
          <Contact />
          <Footer />
        </div>
      </SimpleReactLightbox>
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        mainImageUrl {
          childImageSharp {
            fluid(maxWidth: 2024) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        images {
          galleryImages {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
